<template>
  <div class="edit-single-selection-question-form answers-container">
    <div>
      <ValidationObserver>
        <ul class="list-group">
          <li
            v-for="(item, index) in question.answers"
            :key="index"
            :class="[{ 'list-group-item-secondary': index % 2 === 0 }, 'list-group-item']"
          >
            <div>
              <div class="d-flex w-100 justify-content-between header">
                <h5 class="mb-1 answer-number-title">{{ $t(`QUESTION_DETAILS_PAGE_ANSWER_NUMBER`, { number: index + 1 }) }}</h5>
                <div class="buttons">
                  <ValidationProvider name="QUESTION_DETAILS_PAGE_SINGLE_IS_CORRECT_LABEL">
                    <div class="btn-switch-container">
                      <label class="mr-1">{{ $t('QUESTION_DETAILS_PAGE_SINGLE_IS_CORRECT_LABEL') }}</label>
                      <div class="btn-switch-wrapper">
                        <label class="btn-switch">
                          <input type="checkbox" class="checkbox" v-model="item.is_correct_flg" />
                          <span class="check-silder"></span>
                        </label>
                      </div>
                    </div>
                  </ValidationProvider>

                  <CButton class="btn btn-remove-answer" @click="question.answers.splice(index, 1)">
                    <CIcon name="cil-minus" />
                  </CButton>
                </div>
              </div>
              <CRow>
                <CCol lg="6" md="6">
                  <ValidationProvider :name="$t(`QUESTION_DETAILS_PAGE_ENGLISH_ANSWER_TEXT_FIELD`, { number: index + 1 })" rules="required">
                    <div class="form-group" slot-scope="{ errors }">
                      <label>{{ $t('QUESTION_DETAILS_PAGE_ENGLISH_ANSWER_TEXT_LABEL', { number: index + 1 }) }}</label>
                      <input type="text" v-model="item.text" class="form-control" />
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{ errors[0] }}</span>
                      </span>
                    </div>
                  </ValidationProvider>
                </CCol>

                <CCol lg="6" md="6">
                  <label>{{ $t('QUESTION_DETAILS_PAGE_JAPANESE_ANSWER_TEXT_LABEL', { number: index + 1 }) }}</label>
                  <input type="text" v-model="item.text_ja" class="form-control" />
                </CCol>
              </CRow>
            </div>
          </li>
        </ul>

        <div class="answer-bottom-buttons row mt-2">
          <div class="col-md-12 text-right">
            <CButton class="btn btn-add-answer" @click="addAnswer()">
              <CIcon name="cil-plus" />
            </CButton>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { MembershipTypeName } from '@/enums';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'SingleSelectionAnswers',
  data() {
    return {
      isEdit: false,
    };
  },
  props: {
    question: {
      type: Object,
      default: () => {},
    },
    questionId: {
      type: String,
      default: '',
    },
  },
  mounted() {
    if (this.questionId) {
      this.isEdit = true;
    } else {
      this.question.answers = [
        {
          text: '',
          text_ja: '',
          is_correct_flg: false,
        },
        {
          text: '',
          text_ja: '',
          is_correct_flg: false,
        },
      ];
    }
  },
  methods: {
    addAnswer() {
      this.question.answers.push({
        text: '',
        text_ja: '',
        is_correct_flg: false,
      });
    },
  },
};
</script>

<style lang="scss">
.edit-single-selection-question-form {
  @media only screen and (max-width: 767px) {
    padding: 0;
  }
  h2 {
    font-size: 16px;
    font-weight: 700;
    color: #657187;
    margin-bottom: 20px;
  }
}
</style>
