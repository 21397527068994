<template>
  <div>
    <CRow class="form-group">
      <CCol lg="3" md="3">
        <ValidationProvider :name="$t('QUESTION_DETAILS_PAGE_TYPE_LABEL')" v-slot="{ errors }" rules="required">
          <CSelect
            :label="$t('QUESTION_DETAILS_PAGE_TYPE_LABEL')"
            :options="questionTypeList"
            :value.sync="questionDetails.question_type"
          />
          <p class="group-err-msg">
            <span class="error-msg mt-1">{{ errors[0] }}</span>
          </p>
        </ValidationProvider>
      </CCol>

      <CCol v-if="!isSurvey" lg="3" md="3">
        <ValidationProvider name="QUESTION_DETAILS_PAGE_POINTS_LABEL" rules="required|min_value:1">
          <div slot-scope="{ errors }">
            <label>{{ $t('QUESTION_DETAILS_PAGE_POINTS_LABEL') }}</label>
            <input type="text" v-model="questionDetails.points" class="form-control" />
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </div>
        </ValidationProvider>
      </CCol>

      <CCol v-if="!isSurvey" lg="3" md="3">
        <ValidationProvider name="QUESTION_DETAILS_PAGE_ESTIMATE_TIME_FIELD" rules="required|min_value:1">
          <div slot-scope="{ errors }">
            <label>{{ $t('QUESTION_DETAILS_PAGE_ESTIMATE_TIME_LABEL') }}</label>
            <input type="text" v-model="questionDetails.estimate_time" class="form-control" />
            <span class="error-msg-wrap">
              <span class="error-msg">{{ errors[0] }}</span>
            </span>
          </div>
        </ValidationProvider>
      </CCol>

      <CCol v-if="!isSurvey" lg="3" md="3">
        <ValidationProvider name="QUESTION_DETAILS_PAGE_ACTIVED_LABEL">
          <div>
            <label>{{ $t('QUESTION_DETAILS_PAGE_ACTIVED_LABEL') }}</label>
            <div class="btn-switch-wrapper">
              <label class="btn-switch">
                <input type="checkbox" class="checkbox" v-model="questionDetails.actived_flg" />
                <span class="check-silder"></span>
              </label>
            </div>
          </div>
        </ValidationProvider>
      </CCol>
    </CRow>

    <CRow class="">
      <CCol lg="6" md="6">
        <h4>{{ $t('QUESTION_DETAILS_COMPONENT_ENGLISH_TAB_TITLE') }}</h4>
        <CRow class="form-group mt-3">
          <CCol lg="12" md="12">
            <ValidationProvider name="QUESTION_DETAILS_PAGE_TITLE_FIELD_NAME" rules="required">
              <div slot-scope="{ errors }">
                <label>{{ $t('QUESTION_DETAILS_PAGE_TITLE_FIELD_NAME') }}</label>
                <textarea v-model="questionDetails.title" rows="5" class="form-control"></textarea>
                <span class="error-msg-wrap">
                  <span class="error-msg">{{ errors[0] }}</span>
                </span>
              </div>
            </ValidationProvider>
          </CCol>
        </CRow>
      </CCol>

      <CCol lg="6" md="6">
        <h4>{{ $t('QUESTION_DETAILS_COMPONENT_JAPANESE_TAB_TITLE') }}</h4>
        <CRow class="form-group mt-3">
          <CCol lg="12" md="12">
            <ValidationProvider name="QUESTION_DETAILS_PAGE_TITLE_FIELD_NAME" rules="">
              <div slot-scope="{ errors }">
                <label>{{ $t('QUESTION_DETAILS_PAGE_TITLE_FIELD_NAME') }}</label>
                <textarea v-model="questionDetails.title_ja" rows="5" class="form-control"></textarea>
                <span class="error-msg-wrap">
                  <span class="error-msg">{{ errors[0] }}</span>
                </span>
              </div>
            </ValidationProvider>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <h2>{{ $t('QUESTION_DETAILS_PAGE_SINGLE_ANSWERS_LABEL') }}</h2>

    <div class="wrap-form">
      <SingleSelectionAnswers
        v-if="questionDetails.question_type === 'SINGLE_SELECTION'"
        :question="questionDetails"
        :questionId="questionId"
      />
      <MultiSelectionsAnswers
        v-if="questionDetails.question_type === 'MULTIPLE_SELECTIONS'"
        :question="questionDetails"
        :questionId="questionId"
      />
      <OpenEndedAnswers v-if="questionDetails.question_type === 'OPEN_ENDED'" :question="questionDetails" :questionId="questionId" />
      <OpenEndedAnswers
        v-if="questionDetails.question_type === 'NUMERIC_OPEN_ENDED'"
        :question="questionDetails"
        :questionId="questionId"
      />
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { Constants, env } from '@/constants';
import StakingModal from '@/components/StakingModal';
import SingleSelectionAnswers from '@/components/Questions/SingleSelectionAnswers';
import MultiSelectionsAnswers from '@/components/Questions/MultiSelectionsAnswers';
import OpenEndedAnswers from '@/components/Questions/OpenEndedAnswers';
import { integer } from 'vee-validate/dist/rules';

export default {
  name: 'EditQuestionComponent',
  components: {
    SingleSelectionAnswers,
    MultiSelectionsAnswers,
    OpenEndedAnswers,
  },
  data() {
    return {
      errorMessage: '',
      questionTypeList: [],
    };
  },
  props: {
    questionDetails: {
      type: Object,
      default: () => {
        return {
          answers: [],
        };
      },
    },
    questionId: {
      type: Number,
      default: null,
    },
    isSurvey: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  async mounted() {
    await this.getQuestionsTypes();
  },
  methods: {
    async getQuestionsTypes() {
      try {
        const result = await this.$http.get(endpoints.getQuestionsTypes);
        this.questionTypeList = stringHelper.translateTextForDropdownList(result.data, 'QUESTION_TYPE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.question-details-container,
.survey-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
    .tab-content {
      padding: 10px 10px 0 10px;
    }
    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
      .question-type {
        padding-top: 10px;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }

    .answers-container {
      .header {
        .answer-number-title {
          font-weight: bold;
          font-size: 1em;
        }

        .buttons {
          text-align: right;

          .btn-switch-container {
            display: inline-block;
            margin-right: 10px;

            > label {
              height: 20px;
            }

            .btn-switch-wrapper {
              width: 50px;
              display: inline-block;
            }
          }
        }
      }

      .btn-add-answer,
      .btn-remove-answer {
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 35px;
        font-weight: 700;
        color: #fff;
        background: #0d0da9;
        padding: 0;
        margin: 0 0 0 10px;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        vertical-align: top;
      }

      .answer-bottom-buttons {
        padding: 0.75rem 1.25rem;
      }
    }

    .error-message {
      color: red;
    }
  }
}
</style>
