<template>
  <div class="question-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="questionListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('QUESTION_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody v-if="!isLoading">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, dirty }">
              <CForm @submit.prevent="handleSubmit(onSubmit)">
                <EditQuestionComponent :questionDetails="questionDetails" :questionId="questionId" />


                <div class="error-message" v-if="errorMessage">
                  <div>{{ errorMessage }}</div>
                </div>
                <CRow class="form-actions mt-3">
                  <CCol lg="12" md="12">
                    <CButton @click="cancel(dirty)" class="btn btn-secondary btn-cancel">{{
                      $t('QUESTION_DETAILS_PAGE_CANCEL_BUTTON')
                    }}</CButton>
                    <CButton @click="onSubmit()" v-if="!isEdit && isAllowedCreate" color="primary" class="btn btn-update btn-create">{{
                      $t('QUESTION_DETAILS_PAGE_CREATE_BUTTON')
                    }}</CButton>
                    <CButton @click="onSubmit()" v-if="isEdit && isAllowedUpdating" color="primary" class="btn btn-update">{{
                      $t('QUESTION_DETAILS_PAGE_UPDATE_BUTTON')
                    }}</CButton>
                  </CCol>
                </CRow>
              </CForm>
            </ValidationObserver>
          </CCardBody>
        </CCard>

        <StakingModal :linkTo="questionListUrl" :warningLeavePage="warningLeavePage" @close-modal="closeModal" />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { Constants, env } from '@/constants';
import StakingModal from '@/components/StakingModal';
import SingleSelectionAnswers from '@/components/Questions/SingleSelectionAnswers';
import MultiSelectionsAnswers from '@/components/Questions/MultiSelectionsAnswers';
import OpenEndedAnswers from '@/components/Questions/OpenEndedAnswers';
import EditQuestionComponent from '@/components/Questions/EditQuestionComponent';

export default {
  name: 'EditQuestion',
  components: {
    VclList,
    StakingModal,
    // SingleSelectionAnswers,
    // MultiSelectionsAnswers,
    // OpenEndedAnswers,
    EditQuestionComponent,
  },
  data() {
    return {
      questionId: null,
      questionTypeList: [],
      isEdit: false,
      isLoading: true,
      isAllowedUpdating: false,
      isAllowedCreate: false,
      warningLeavePage: false,
      errorMessage: '',
      questionListUrl: '/admin/setting/questions',
      questionDetails: {
        question_type: 'SINGLE_SELECTION',
        answers: [
          {
            text: '',
            text_ja: '',
            is_correct_flg: false,
          },
          {
            text: '',
            text_ja: '',
            is_correct_flg: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
  },
  async mounted() {
    const questionId = this.$route.params.questionId;
    this.checkPermissions();
    await this.getQuestionsTypes();

    if (questionId) {
      this.isEdit = true;
      this.questionId = questionId;
      await this.getQuestionDetails(this.questionId);
      this.isLoading = false;
    } else {
      this.isEdit = false;
      this.isLoading = false;
    }
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach((item) => {
          if (item.name === 'UPDATE_QUESTION') {
            this.isAllowedUpdating = true;
          }
          if (item.name === 'CREATE_QUESTION') {
            this.isAllowedCreate = true;
          }
        });
      }
    },
    async getQuestionsTypes() {
      try {
        const result = await this.$http.get(endpoints.getQuestionsTypes);
        this.questionTypeList = stringHelper.translateTextForDropdownList(result.data, 'QUESTION_TYPE');
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_LIST_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getQuestionDetails(questionId) {
      try {
        const res = await this.$http.get(endpoints.getQuestionsDetails(questionId));
        this.questionDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_DETAILS_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    backToQuestionList() {
      this.$router.push(this.questionListUrl);
    },
    closeModal(val) {
      this.warningLeavePage = val;
    },
    cancel(dirty) {
      if (dirty) {
        this.warningLeavePage = true;
      } else {
        this.$router.push(this.questionListUrl);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      // Make sure question has atleast correct answwer
      const answers = this.questionDetails.answers || [];
      if (answers.length) {
        const hasCorrectAnswer = answers.some((item) => item.is_correct_flg);
        if (!hasCorrectAnswer) {
          this.errorMessage = this.$t('QUESTION_DETAILS_PAGE_QUESTION_NEED_A_CORRECT_ANSWER');
          return;
        }

        if (
          answers.length > 1 &&
          (this.questionDetails.question_type === 'OPEN_ENDED' || this.questionDetails.question_type === 'NUMERIC_OPEN_ENDED')
        ) {
          this.errorMessage = this.$t('QUESTION_DETAILS_PAGE_QUESTION_HAS_ONE_ANSWER');
          return;
        }
      }

      this.errorMessage = '';
      if (this.isEdit) {
        return this.updateQuestion();
      }

      return this.createQuestion();
    },
    async updateQuestion() {
      if (this.questionDetails.isProcessing) {
        return;
      }

      this.questionDetails.isProcessing = true;

      try {
        const data = {
          title: this.questionDetails.title,
          title_ja: this.questionDetails.title_ja || '',
          points: this.questionDetails.points,
          estimate_time: this.questionDetails.estimate_time,
          question_type: this.questionDetails.question_type,
          actived_flg: this.questionDetails.actived_flg || false,
          answers: (this.questionDetails.answers || []).map((answer) => {
            return {
              id: answer.id,
              text: answer.text,
              text_ja: answer.text_ja,
              is_correct_flg: answer.is_correct_flg,
            };
          }),
        };
        const result = await this.$http.put(endpoints.updateQuestions(this.questionId), data);

        this.questionDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('QUESTION_DETAILS_PAGE_UPDATE_SUCCESS_TITLE'),
          text: this.$t('QUESTION_DETAILS_PAGE_UPDATE_SUCCESS_TEXT'),
        });
        this.backToQuestionList();
      } catch (err) {
        this.questionDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_DETAILS_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async createQuestion() {
      if (this.questionDetails.isProcessing) {
        return;
      }

      this.questionDetails.isProcessing = true;
      try {
        const data = {
          title: this.questionDetails.title,
          title_ja: this.questionDetails.title_ja || '',
          points: this.questionDetails.points,
          estimate_time: this.questionDetails.estimate_time,
          question_type: this.questionDetails.question_type,
          actived_flg: this.questionDetails.actived_flg || false,
          answers: this.questionDetails.answers,
        };
        const result = await this.$http.post(endpoints.createQuestions, data);

        this.questionDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('QUESTION_DETAILS_PAGE_CREATE_SUCCESS_TITLE'),
          text: this.$t('QUESTION_DETAILS_PAGE_CREATE_SUCCESS_TEXT'),
        });

        this.backToQuestionList();
      } catch (err) {
        this.questionDetails.isProcessing = false;
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('QUESTION_DETAILS_PAGE_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.question-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }
    .tab-content {
      padding: 10px 10px 0 10px;
    }
    .form-control-plaintext {
      color: #3a4a7f;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 22px;
    }

    .form-group {
      margin-bottom: 0.5rem;
      .col-form-label {
        width: 100%;
        text-align: right;
      }

      .btn-switch-wrapper {
        margin-top: 10px;
      }
      .question-type {
        padding-top: 10px;
      }
    }

    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        height: 36px;

        &.btn-primary {
          background: #3b4b7e;
        }

        &.btn-update {
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.5px;
          text-align: center;
          background-color: #3b4b7e;
          border: 1px solid #3b4b7e;
          border-radius: 4px;
        }

        &.btn-cancel {
          background-color: transparent;
          color: #6c65e4;
          font-size: 14px;
          letter-spacing: 0;
        }
      }
    }

    .answers-container {
      .header {
        .answer-number-title {
          font-weight: bold;
          font-size: 1em;
        }

        .buttons {
          text-align: right;

          .btn-switch-container {
            display: inline-block;
            margin-right: 10px;

            > label {
              height: 20px;
            }

            .btn-switch-wrapper {
              width: 50px;
              display: inline-block;
            }
          }
        }
      }

      .btn-add-answer,
      .btn-remove-answer {
        width: 35px;
        height: 35px;
        font-size: 18px;
        line-height: 35px;
        font-weight: 700;
        color: #fff;
        background: #0d0da9;
        padding: 0;
        margin: 0 0 0 10px;
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        vertical-align: top;
      }

      .answer-bottom-buttons {
        padding: 0.75rem 1.25rem;
      }
    }

    .error-message {
      color: red;
    }
  }
}
</style>
